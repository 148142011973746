<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
          <!-- <user-view-user-documents-card :user-data="userData" /> -->
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <posts-crud :user-data="userData" />
        </b-col>
        <!-- <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col> -->
      </b-row>
      <!-- <invoice-list /> -->  </template>

  </div>
</template>

<script>
import store from '@/store'
// import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import epaStoreModule from '../epasStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import PostsCrud from './PostsCrud.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
// import UserViewUserDocumentsCard from './UserViewUserDocumentsCard.vue'

export default {

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    // eslint-disable-next-line no-undef
    UserViewUserPlanCard,
    // eslint-disable-next-line vue/no-unused-components
    UserViewUserTimelineCard,
    PostsCrud,
    // UserViewUserPermissionsCard,
    // UserViewUserDocumentsCard,
    // InvoiceList,
  },
  setup() {
    const userData = ref(null)

    const EPAS_APP_STORE_MODULE_NAME = 'app-epas'
    // console.log(store.state.app.userData.id)
    // console.log(store.state.app.userData.providerData)
    // Register module
    if (!store.hasModule(EPAS_APP_STORE_MODULE_NAME)) store.registerModule(EPAS_APP_STORE_MODULE_NAME, epaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EPAS_APP_STORE_MODULE_NAME)) store.unregisterModule(EPAS_APP_STORE_MODULE_NAME)
    })

    // console.log(888)
    store.dispatch('app-epas/getEpaData', { id_partners: store.state.app.userData.id_partners })
      .then(response => {
        const infoIn = response.data
        infoIn.providerData = store.state.app.userData.providerData
        userData.value = infoIn
      })
      .catch(error => {
        if (error) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
