/* eslint-disable prefer-template */
<template>
  <b-card
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"

    title="Lista Posts"
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-primary"
        variant="primary"
        @click="modalCreatePosts = !modalCreatePosts"
      >
        Crear Post
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn btn-primary"
        variant="primary"
        @click="modalCreateType = !modalCreateType"
      >
        Crear Type
      </b-button>
    </div>

    <b-modal
      v-model="modalCreatePosts"
      title="Crear Posts"
      ok-title="Crear"
      cancel-title="Cerrar"
      size="l"
      :ok-disabled="type.length == '' || Selected == 'A' ? dateNtim.length == '' : false || Selected == 'B' ? fechatermino == '' : false "
      @ok="crear"
    >
      <!--b-form-group
        id="formgroup"
        label="type"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          v-model="type"
          placeholder="Ingresa Type"
        />
      </b-form-group!-->
      <b-form-group
        label="Selecciona Type:"
        label-for="typeselec"
      >
        <b-form-select
          id="typeselec"
          v-model="type"
          required
          :state="type.length > 0"
          @change="seleccionOp($event)"
        >
          <option
            v-for="item in types"
            :key="item._id.tipo"
            :value="item.name"
          >
            {{ item.name }}
          </option>
        </b-form-select>
        <b-form-invalid-feedback tooltip>
          Type es requerido
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="formgroup"
        label="descripcion"
        label-for="desc"
      >
        <b-form-input
          id="desc"
          v-model="descripcionPost"
          disabled
        />
      </b-form-group>
      <b-form-group>
        <b-form-radio
          v-model="Selected"
          plain
          name="some-radios3"
          value="A"
          @change="cambioRadio"
        >
          Turnos Clasicos
        </b-form-radio>
        <b-form-radio
          v-model="Selected"
          plain
          name="some-radios3"
          value="B"
          @change="cambioRadio"
        >
          Turnos Recurrentes
        </b-form-radio>
      </b-form-group>
      <b-form-group
        v-if="Selected == 'A'"
        label="fecha"
        label-for="fechas"
      >
        <flat-pickr
          id="fechas"
          v-model="dateNtim"
          class="form-control"
          :config="{ enableTime: true,dateFormat: 'Y-m-d'}"
        />
      </b-form-group>
      <b-form-group
        v-if="Selected == 'A'"
        label="Cantidad Posts"
        label-for="cantidadselec"
      >
        <b-form-select
          v-if="Selected == 'A'"
          id="cantidadselec"
          v-model="cantidadselec"
          label-field="repetir cada"
          :options="optionsCantidad"
        />
      </b-form-group>
      <span v-if="Selected == 'B'">Repetir cada : </span>
      <b-row v-if="Selected == 'B'">
        <b-col>
          <b-form-input
            id="repetirnumerocada"
            v-model="repetircadanumero"
            class="my-1"
            number
            @change="changeOptions"
          />
        </b-col>
        <b-col>
          <b-form-select
            id="repetircada"
            v-model="repetircada"
            :options="repetircadaoptions"
            class="my-1"
            @change="changeOptions"
          />
        </b-col>
      </b-row>
      <b-form-group v-if="mostrarDias == true && Selected == 'B'">
        <label
          v-for="day in days"
          :key="day.title"
          class="label-checkbox"
          :class="{ 'checked': day.checked }"
          :for="day.title.toLowerCase()"
        >
          <input
            :id="day.title.toLowerCase()"
            v-model="day.checked"
            type="checkbox"
            class="check-day"
            :value="day.title"
            @change="changeOptions"
          >
          {{ day.title }}
        </label>
      </b-form-group>
      <span v-if="Selected == 'B'">{{ mensajeRepetir }}</span>
      <b-form-group
        label="Cantidad de cupos:"
        label-for="cuposPost"
      >
        <b-form-input
          id="cuposPost"
          v-model="cuposPost"
          type="number"
          size="2"
        />
      </b-form-group>
      <br><br>
      <span v-if="Selected == 'B'">Termina:</span>

      <flat-pickr
        v-if="Selected == 'B'"
        id="fechas"
        v-model="fechatermino"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'Y-m-d'}"
      />
    </b-modal>
    <b-modal
      v-model="modalCreateType"
      title="Crear Type"
      ok-title="Crear"
      cancel-title="Cerrar"
      size="m"
      @ok="crearType"
    >
      <b-form-group
        id="formgroup"
        label="type"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          v-model="nombreType"
          placeholder="Ingresa Type"
        />
      </b-form-group>

      <b-form-group
        id="formgroup"
        label="descripcion"
        label-for="desc"
      >
        <b-form-input
          id="desc"
          v-model="descripcionType"
          placeholder="Ingresa Descripcion"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      id="modalEliminarPosts"
      v-model="modalEliminarPosts"
      title="Eliminar Posts"
      ok-title="Sí"
      cancel-title="No"
      size="m"
      @ok="eliminarPosts"
    >
      Estas apunto de eliminar un Posts, ¿Estas seguro?
    </b-modal>

    <b-modal
      id="modalActualizarPosts"
      v-model="modalActualizarPosts"
      title="Actualizar Posts"
      ok-title="Sí"
      cancel-title="No"
      size="m"
      @ok="actualizarPosts"
    >
      Posts ID : {{ selectedPostUpdate._id }}

      <!--b-form-group
          id="formgroup"
          label="nombre"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            v-model="nombrePost"
            placeholder="Ingresa nombre"
          />
        </b-form-group-->

      <b-form-group
        label="Status"
        label-for="statusselec"
      >
        <b-form-select
          id="statusselec"
          v-model="selectedStatus"
          :options="options"
          disabled
        />
      </b-form-group>

      <b-form-group
        label="Cantidad de cupos:"
        label-for="cuposPostPop"
      >
        <b-form-input
          id="cuposPostPop"
          v-model="cuposPostPop"
          type="number"
          size="2"
        />
      </b-form-group>

      <!--b-form-group
          id="formgroup"
          label="descripcion"
          label-for="desc"
        >
          <b-form-input
            id="desc"
            v-model="descripcionPost"
            placeholder="Ingresa Descripcion"
          />
        </b-form-group>

        <b-form-group
          label="fecha"
          label-for="desc"
        >
        <flat-pickr
            v-model="dateNtim"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group-->

    </b-modal>

    <b-table
      striped
      hover
      responsive
      :items="posts"
      class="mb-0"
      :fields="fields"
      sticky-header="true"
      sticky-column="true"
    >
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <b-button
          v-b-modal="'modalEliminarPosts'"
          @click="sendInfo(data.item._id)"
        ><feather-icon
          icon="TrashIcon"
          class="mr-50"
        /><span>Eliminar</span>
        </b-button>
        <br><br>
        <b-button
          v-b-modal="'modalActualizarPosts'"
          @click="editInfo(data.item)"
        ><feather-icon
          icon="Edit2Icon"
          class="mr-50"
        />Editar
        </b-button>
        <br><br>
        <b-button
          size="sm"
          class="mr-2"
          @click="data.toggleDetails"
        >
          <feather-icon icon="EyeIcon" /> {{ data.detailsShowing ? 'Ocultar' : 'Ver' }} Cupos
        </b-button>
      </template>

      <template #row-details="data">
        <b-card>
          <b-table
            :fields="fields2"
            :items="data.item.cupos"
          >
          </b-table>

          <b-button
            size="sm"
            @click="data.toggleDetails"
          >
            Ocultar Cupos
          </b-button>
        </b-card>
      </template>
    </b-table>
  </b-card>
</template>

<script>
// import store from '@/store'
import {
  BCard, BTable,
  // BImg, BMedia, BAvatar,BButton,
  VBModal,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BButton,
  BCol,
  BRow,
  BFormInvalidFeedback,
  // BFormRadioGroup,
  BFormRadio,
  // BRadio,
} from 'bootstrap-vue'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
// import * as Realm from 'realm-web'
// import { BSON } from 'realm-web'
import * as moment from 'moment-timezone'
// import { validatorCreditCard, validatorPositive } from '@/@core/utils/validations/validators'
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars

// import {
//   // eslint-disable-next-line no-unused-vars
//   ObjectId,
// } from 'mongodb'
// import userStoreModule from '../userStoreModule'

export default {
  directives: {
    'b-modal': VBModal,
    BTable,
    Ripple,
  },
  components: {
    // BButton,
    BTable,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    flatPickr,
    BButton,
    BCol,
    BRow,
    BFormRadio,
    BFormInvalidFeedback,
    // BFormRadioGroup,
    // BFormRadio,
    // BRadio,
    // BImg,
    // BMedia,
    // BAvatar,
    // AppTimeline,
    // AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // app: new Realm.App({ id: 'partnersrayoappcom-ucuyv' }),
      mongodb: {},
      postsDB: {},
      posts: [],
      fields: ['type', 'date', 'desc', 'status', 'action'],
      fields2: [],
      rayos: [],
      // swiperData: [
      //   { img: this.userData.responses },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      // ],
      imagesFromDocs: [],
      modalCreatePosts: false,
      modalCreateType: false,
      modalEliminarPosts: false,
      modalActualizarPosts: false,
      dateNtim: '',
      nombrePost: null,
      descripcionPost: null,
      postsResult: null,
      typeResult: null,
      selectedUser: '',
      selectedPostUpdate: '',
      nombreType: '',
      descripcionType: '',
      types: '',
      id: '',
      type: '',
      status: '',
      repetircada: '',
      repetircadanumero: '',
      mensajeRepetir: '',
      mostrarDias: false,
      radios: '',
      selected: '',
      fechatermino: '',
      fechas: [],
      fecha: '',
      Selected: 'A',
      Selected1: 'c',
      cuposPost: '',
      cuposPostPop: '',
      selectedStatus: 'Disponible',
      repetircadaoptions: [
        { value: 'dias', text: 'días' },
        { value: 'semanas', text: 'semanas' },
      ],
      options: [
        { value: null, text: 'Seleccione ...' },
        { value: 'Disponible', text: 'Disponible' },
        { value: 'Reservada', text: 'Reservada' },
      ],
      cantidadselec: '',
      optionsCantidad: [
        { value: 0, text: 'Seleccione ...' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
      ],
      days: [
        { title: 'L' },
        { title: 'M' },
        { title: 'X' },
        { title: 'J' },
        { title: 'V' },
        { title: 'S' },
        { title: 'D' },
      ],
    }
  },
  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = 'postulaciones'
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  // },
  async mounted() {
    this.mongodb = store.getters['app/realmApp'].currentUser.mongoClient('mongodb-atlas')
    // this.postsDB = await store.getters['app/realmApp'].currentUser.functions.readPosts()
    // console.log(await store.getters['app/realmApp'].currentUser.functions.readPosts())
    this.postsDB = this.mongodb.db('fleet_manager').collection('posts')
    // console.log(store.getters['app/realmApp'])
    // console.log(
    // //  await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef'),
    // )
    this.findPosts()
    this.findRayos()
    this.findTypes()
    this.setWatchs()
  },
  methods: {
    cambioRadio() {
      this.repetircada = ''
      this.repetircadanumero = ''
      this.mensajeRepetir = ''
      this.mostrarDias = false
      this.radios = ''
      this.selected = ''
      this.fechatermino = ''
      this.fechas = []
      this.fecha = ''
      this.dateNtim = ''
      this.cantidadselec = ''
      // this.days[moment().isoWeekday() - 1].checked = true
    },
    changeOptions() {
      this.mensajeRepetir = `Se repite cada ${this.repetircadanumero} ${this.repetircada}`
      // eslint-disable-next-line radix
      let valor = parseInt(this.repetircadanumero)
      let valorincrementalsemanas = 7
      const seleccionados = []
      // eslint-disable-next-line no-plusplus
      if (this.repetircada === 'semanas') {
        this.fechas = []
        this.mostrarDias = true
        this.fechatermino = moment().add(this.repetircadanumero, 'weeks').format('YYYY-MM-DD')

        // eslint-disable-next-line no-plusplus
        for (let i = 0; this.days.length > i; i++) {
          if (this.days[i].checked === true) {
            if (this.days[i].title === 'L') {
              seleccionados.push(1)
            } else if (this.days[i].title === 'M') {
              seleccionados.push(2)
            } else if (this.days[i].title === 'X') {
              seleccionados.push(3)
            } else if (this.days[i].title === 'J') {
              seleccionados.push(4)
            } else if (this.days[i].title === 'V') {
              seleccionados.push(5)
            } else if (this.days[i].title === 'S') {
              seleccionados.push(6)
            } else if (this.days[i].title === 'D') {
              seleccionados.push(0)
            }
          }
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 0; seleccionados.length > i; i++) {
          /* console.log('.....')
          console.log(seleccionados.length)
          console.log(seleccionados[i])
          console.log('_________') */
          valorincrementalsemanas = 7
          // eslint-disable-next-line no-plusplus
          for (let j = 0; this.repetircadanumero > j; j++) {
            this.fecha = moment().day(seleccionados[i] + valorincrementalsemanas).format('YYYY-MM-DD')
            this.fechas.push(this.fecha)
            valorincrementalsemanas += 7
          }
        }
      } else {
        this.fechas = []
        this.mostrarDias = false
        this.fechatermino = moment().add(this.repetircadanumero, 'days').format('YYYY-MM-DD')
        // eslint-disable-next-line no-plusplus
        for (let x = 0; valor > x; x++) {
          this.fecha = moment().add(x, 'days').format('YYYY-MM-DD')
          this.fechas.push(this.fecha)
        }
      }
      // console.log(valor)
      valor = 0
      // console.log(this.repetircadanumero)
      // console.log(this.fechas)
      // console.log(this.fechatermino)
      // console.log(moment().day(1 + 7).format('YYYY-MM-DD'))
    },
    seleccionOp(event) {
      /* eslint-disable no-plusplus */
      // console.log(event)
      for (let i = 0; i < this.types.length; i++) {
        if (event === this.types[i].name) {
          //  console.log(this.types[i].desc)
          this.descripcionPost = this.types[i].desc
        }
      }
    },
    async  createPosts(type, descripcion, date, cant, recurrente, listafechas, cantidadCupos) {
      // const result = await this.postsDB.find({ }) // *****????
      this.postsResult = await store.getters['app/realmApp'].currentUser.functions.createPosts(type, descripcion, date, cant, recurrente, listafechas, cantidadCupos)
      this.descripcionPost = ''
      this.type = ''
      this.findTypes()
      this.findPosts()
      // return result
    },
    async  createType(type, descripcion) {
      // const result = await this.postsDB.find({ }) // *****????
      this.typeResult = await store.getters['app/realmApp'].currentUser.functions.createType(type, descripcion)
      this.nombreType = ''
      this.descripcionType = ''
      this.findTypes()
      // return result
    },
    async  deletePosts(idPost) {
      // const result = await this.postsDB.find({ }) // *****????
      this.postsResult = await store.getters['app/realmApp'].currentUser.functions.deletePosts(idPost)
      this.findTypes()
      this.findPosts()
      // return result
    },
    async  updatePosts(id, cantidadPost) {
      // const result = await this.postsDB.find({ }) // *****????
      await store.getters['app/realmApp'].currentUser.functions.updateReservation(id, 'Disponible')
      this.postsResult = await store.getters['app/realmApp'].currentUser.functions.updatePosts(id, cantidadPost)

      this.findTypes()
      this.findPosts()
      // return result
    },
    async  findPosts() {
      /* eslint-disable no-plusplus */
      // const result = await this.postsDB.find({ }) // *****????
      this.posts = await store.getters['app/realmApp'].currentUser.functions.readPosts()
      for (let i = 0; i < this.posts.length; i++) {
        this.posts[i].date = moment.utc(this.posts[i].date).format('YYYY-MM-DD')
      }
    /*  console.log('+++++++++')
      console.log(this.posts) */
      // return result
    },
    async  findRayos() {
      // const result = await this.postsDB.find({ }) // *****????
      this.rayos = await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef')
      // return result
    },
    async  findTypes() {
      // const result = await this.postsDB.find({ }) // *****????
      this.types = await store.getters['app/realmApp'].currentUser.functions.readType()
      // return result
    },
    async  setWatchs() {
      // eslint-disable-next-line no-restricted-syntax
      for await (const change of this.postsDB.watch()) {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line default-case
        switch (change.operationType) {
          case 'insert': {
          //   // eslint-disable-next-line no-restricted-syntax
          //   const { documentKey, fullDocument } = change
          //   // eslint-disable-next-line no-restricted-syntax
          //   this.findPosts()
          //   console.log(`new document: ${documentKey}`, fullDocument)
            break
          } case 'update': {
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line no-unused-vars
            const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-underscore-dangle
            // console.log(documentKey._id)
            // console.log({ _id: BSON.ObjectID('61df68791eea470debce4c79') })
            // console.log('9999')
            this.findPosts()
            // eslint-disable-next-line no-underscore-dangle
            //     console.log(this.posts.filter(x => x._id.toString() === BSON.ObjectID(documentKey._id).toString()))

            break
          }
          // case 'replace': {
          //   const { documentKey, fullDocument } = change
          //   console.log(`replaced document: ${documentKey}`, fullDocument)
          //   break
          // }
          // case 'delete': {
          //   const { documentKey } = change
          //   this.findPosts()
          //   console.log(`deleted document: ${documentKey}`)
          //   break
          // }
        }
      }
    },
    // crearPartners(dataIn) {
    //   console.log(dataIn)
    //   store.dispatch('postulaciones/createEpaPartnersServer', dataIn)
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       if (error.response.status === 404) {
    //         console.log(false)
    //       }
    //     })

    //   return {
    //     a: false,
    //   }
    // },
    getDefinition(id) {
      // console.log(id)
      return this.userData.documents.responses.definition.fields.find(x => x.id === id)
    },
    crear() {
      // console.log(this.fechas)
      // console.log(this.descripcionPost)
      // console.log(this.type)
      let recurrente = false
      if (this.Selected === 'A') {
        recurrente = false
      } else {
        recurrente = true
      }

      this.createPosts(this.type, this.descripcionPost, this.dateNtim, this.cantidadselec, recurrente, this.fechas, this.cuposPost)

      this.type = ''
      this.descripcionPost = ''
      this.dateNtim = ''
      this.cantidadselec = ''
      this.fechas = []
      this.cantidadselec = ''
      this.repetircadanumero = ''
      this.fechatermino = ''
      this.cuposPost = ''
    },
    crearType() {
      // console.log(this.nombreType)
      // console.log(this.descripcionType)
      this.createType(this.nombreType, this.descripcionType)
    },
    eliminarPosts() {
      // console.log(this.selectedUser)
      this.deletePosts(this.selectedUser)
    },
    sendInfo(item) {
      // console.log(item.toHexString())
      this.selectedUser = item.toHexString()
    },
    editInfo(item) {
      // console.log('-----')
      // console.log(item)
      this.selectedPostUpdate = item
      this.dateNtim = this.selectedPostUpdate.date
      this.descripcionPost = this.selectedPostUpdate.desc
      this.nombrePost = this.selectedPostUpdate.test
    },
    actualizarPosts() {
      // console.log(this.dateNtim)
      // console.log(this.descripcionPost)
      // console.log(this.nombrePost)
      // eslint-disable-next-line no-param-reassign, no-underscore-dangle
      this.id = this.selectedPostUpdate._id
      // this.id = this.selectedPostUpdate._id.toHexString()
      // console.log(this.id)
      // console.log(this.status)
      // this.cuposPostPop
      // console.log(this.id)
      // console.log(this.cuposPostPop)
      this.updatePosts(this.id, this.cuposPostPop)
    },
  },
}
</script>

<style>
.expan {
  width: 140px;
  max-width: 100%;
}
.label-checkbox {
  margin-right: 0.87rem;
  margin-left: auto;
  border: 1px solid #4273DE;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #4273DE;
  width: 30px;
}

.check-day {
  visibility: hidden;
  position: absolute;
  right: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checked {
  background: #4273DE;
  color: #fff;
}

/*.checked::before {
  content: "✔";
}*/

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
