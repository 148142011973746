/* eslint-disable no-self-compare */
/* eslint-disable no-self-compare */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
<template>
  <b-card
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"

    title="Reservas disponibles"
  >
    <!-- <b-button
        class="mt-1"
        variant="primary"

        @click="crearPartners(userData.documents.responses.answers)"
      >
        Crear en Partners
      </b-button> -->

    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mt-1"
      variant="primary"

      @click="crearPartners(userData.documents.responses.answers)"
    >
      Crear en Partners
    </b-button> -->
    <b-table
      striped
      hover
      responsive
      :items="posts"
      class="mb-0"
    />

    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        class="mt-4"
        variant="info"
        title="EPAS"
      />
      <!-- icon="CameraIcon" -->
      <app-timeline-item
        v-for="(data,index) in posts"
        :key="index"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ posts[index] }} </h6>
          <small class="text-muted">{{ userData.documents.ts }}</small>
        </div>
        <!-- <p /> -->
        <!-- Tipo: {{ data.type }} -->

      </app-timeline-item>

      <!-- <app-timeline-item icon="DollarSignIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>12 Invoices have been paid</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Invoices have been paid to the company.</p>
        <p class="mb-0">
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>
      </app-timeline-item> -->

      <!-- <app-timeline-item variant="warning">

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Client Meeting</h6>
          <small class="text-muted">45 min ago</small>
        </div>
        <p>Project meeting with john @10:15am.</p>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
          </template>
          <h6>John Doe (Client)</h6>
          <p class="mb-0">
            CEO of Infibeam
          </p>
        </b-media>
      </app-timeline-item>
      <app-timeline-item
        variant="info"
        title="Create ass new project for client"
        subtitle="Add files to new design folder"
        time="2 days ago"
      /> -->
    </app-timeline>
  </b-card>
</template>

<script>
// import store from '@/store'
import {
  BCard, BTable,
  // BImg, BMedia, BAvatar,BButton,
  VBModal,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import * as Realm from 'realm-web'
import { BSON } from 'realm-web'
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars

// import {
//   // eslint-disable-next-line no-unused-vars
//   ObjectId,
// } from 'mongodb'
// import userStoreModule from '../userStoreModule'

export default {
  directives: {
    'b-modal': VBModal,
    BTable,
    Ripple,
  },
  components: {
    // BButton,
    BTable,
    BCard,
    // BImg,
    // BMedia,
    // BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // app: new Realm.App({ id: 'partnersrayoappcom-ucuyv' }),
      mongodb: {},
      postsDB: {},
      posts: [],
      rayos: [],
      // swiperData: [
      //   { img: this.userData.responses },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      // ],
      imagesFromDocs: [],
    }
  },
  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = 'postulaciones'
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  // },
  async mounted() {
    this.mongodb = store.getters['app/realmApp'].currentUser.mongoClient('mongodb-atlas')
    // this.postsDB = await store.getters['app/realmApp'].currentUser.functions.readPosts()
    console.log(await store.getters['app/realmApp'].currentUser.functions.readPosts())
    this.postsDB = this.mongodb.db('fleet_manager').collection('posts')
    console.log(store.getters['app/realmApp'])
    console.log(
    //  await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef'),
    )
    this.findPosts()
    this.findRayos()

    this.setWatchs()
  },
  methods: {
    async  findPosts() {
      // const result = await this.postsDB.find({ }) // *****????
      this.posts = await store.getters['app/realmApp'].currentUser.functions.readPosts()
      // return result
    },
    async  findRayos() {
      // const result = await this.postsDB.find({ }) // *****????
      this.rayos = await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef')
      // return result
    },
    async  setWatchs() {
      // eslint-disable-next-line no-restricted-syntax
      for await (const change of this.postsDB.watch()) {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line default-case
        switch (change.operationType) {
          case 'insert': {
            // eslint-disable-next-line no-restricted-syntax
            const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-restricted-syntax
            console.log(`new document: ${documentKey}`, fullDocument)
            break
          }
          case 'update': {
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line no-unused-vars
            const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-underscore-dangle
            // console.log(documentKey._id)
            // console.log({ _id: BSON.ObjectID('61df68791eea470debce4c79') })
            console.log('9999')
            // eslint-disable-next-line no-underscore-dangle
            console.log(this.posts.filter(x => x._id.toString() === BSON.ObjectID(documentKey._id).toString()))

            break
          }
          case 'replace': {
            const { documentKey, fullDocument } = change
            console.log(`replaced document: ${documentKey}`, fullDocument)
            break
          }
          case 'delete': {
            const { documentKey } = change
            console.log(`deleted document: ${documentKey}`)
            break
          }
        }
      }
    },
    // crearPartners(dataIn) {
    //   console.log(dataIn)
    //   store.dispatch('postulaciones/createEpaPartnersServer', dataIn)
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       if (error.response.status === 404) {
    //         console.log(false)
    //       }
    //     })

    //   return {
    //     a: false,
    //   }
    // },
    getDefinition(id) {
      console.log(id)
      return this.userData.documents.responses.definition.fields.find(x => x.id === id)
    },
  },
}
</script>

<style>
.expan {
  width: 140px;
  max-width: 100%;
}

</style>
